import React, { Component } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PublishIcon from '@material-ui/icons/Publish';
function BtnCellUploadRenderer(props) {
  let isEnable = false
  const mod = sessionStorage.getItem('ARCHIVIO_NUOVAVERSIONE')
  if(mod=== "ENABLE")
  { 
    isEnable= true
  }
      
  const onBtnClickedHandler = (e) => {
    if(isEnable){
      props.row.contents.customFunction(props.row) //setIDDocument      
      props.row.contents.handleClickOpenDocumentVersion()
    }
    else{
      props.setTitleAlert("Attenzione")
      props.setMessageAlert("Funzionalità non disponibile")
      props.setInOpen(true)
    }

  }
     
      return (
        <IconButton aria-label="upload" style={isEnable?{padding: '6px'}:{padding: '6px',color:'#d1d1d1'}} className={isEnable?'text-primary':''} onClick={onBtnClickedHandler}>
        <PublishIcon fontSize="small" />
      </IconButton>
      )
    
  }
  export default BtnCellUploadRenderer;