import React from 'react';
//import 'react-toastify/dist/ReactToastify.css';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
// import {toast, ToastContainer} from 'react-toastify';
// import { saveFileAs } from '../services/DownloadService';
// import { axios } from '../services/axios';
// import { notify } from "../services/Notify";
// import {contentInfoToast,contentErrorToast} from '../utils/Utils'
export default function BtnCellDownloadRenderer(props) {
  const toastId = React.useRef(null);
  const onBtnClickedHandler = async () => {   
    // let docContents=props.row.contents.content 
    // let idDoc=props.row.id
    // var server = sessionStorage.getItem('EDOC_GNL')+'/ned/api/gnl/visualizza/documenti/';  
    // if(docContents !== undefined){     
    //   var url = server + idDoc + '/content' 
    //   console.log('Sending request for file url : ', url)
    //   var method = 'GET'
    //   toastId.current = toast.info(contentInfoToast("Download in corso ..."), {
    //     className: 'toast-info-container toast-info-container-after',
    //     position: "bottom-right", 
    //     autoClose: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    // })
    //   const response = await axios
    //         .request({
    //           url,
    //           method,
    //           responseType: 'blob', //important,
    //           onDownloadProgress : p => {
    //             // progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    //             const progress = p.loaded / p.total;

    //             // check if we already displayed a toast
    //             if(toastId.current === null){
    //                 toastId.current = toast.info('Download in corso...', {
    //                   className: 'toast-info-container toast-info-container-after',
    //                   position: "bottom-right", 
    //                   closeOnClick: true,
    //                   pauseOnHover: true,
    //                   draggable: true,
    //                   progress: progress,
    //               })
    //             } else {
    //               toast.update(toastId.current, {                                        
    //                 progress: progress,
    //               })
    //             }
    //           }
    //         })
          
    //       .catch((error) => {
    //           notify(contentErrorToast(error,"Errore durante il download del file:"), "error")             
    //       });
    //   if (response != undefined) {
    //     toast.done(toastId.current);        
    //     notify("Download eseguito", "success")
    //     console.log('Data : ', response.data)
    //     var contentDisposition= response.headers["content-disposition"] 
    //     //"attachment; filename=\"ANALISI MICROCLIMATICHE E DI QUALITA' DELL'ARIA - 2020.12.07 - [Descrizione AAA].pdf\""
    //     let stringFileName = String(contentDisposition).substring(String(contentDisposition).indexOf('filename'))
    //     var fileName=String(stringFileName).substring(String(stringFileName).indexOf('=')+2,String(stringFileName).lastIndexOf('"'))
    //     saveFileAs(response.data, fileName)

    //   }
    // }   
  }
    // const onBtnClickedHandler = (e) => {
     
    //   downloadDoc(props.row.id,props.row.contents.content);
    // }
   
  return (
    <IconButton aria-label="download" style={{padding: '6px'}}  className={'text-primary'}   onClick={onBtnClickedHandler}>
      <GetAppIcon  fontSize="small"/>
    </IconButton>
  )
    
}
