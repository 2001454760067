import React,{ useEffect, useState }  from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BtnCloseFormDialogRenderer from '../CustomButtons/BtnCloseFormDialogRenderer';
import Moment from 'moment';
import NedModelDialogSingleCard from './NedModelDialogSingleCard'


export default function NEDModalDialog({open,setOpen,columnFilterable,setFilters,filters}) {
  console.log('NEDModalDialog -- INIT --')
  const [arrayFilters,setArrayFilters] = useState([])
  const [clearAll,setClearAll]= useState(false)
  // const [optionsState,setOptionState] = useState()
  // const [itemFilterToAdd,setItemFilterToAdd]= useState();
  
    const handleClose = (e) => {

        setOpen(false);
        if (arrayFilters!= null){       
          let temp = []
          temp.push(...arrayFilters)
          setFilters(temp)   
        } 
    };

    const handleClear = (e) => {
      //setClearAll(true)
      setArrayFilters(null)
      setFilters([])
      //setOpen(!open);
    }
    const  optionsDef={ 
      locale:{
        format: "DD/MM/YYYY",
        separator: " - ",
        applyLabel: "Applica",
        cancelLabel: "Chiudi",
        fromLabel: "Da",
        toLabel: "A",  
        weekLabel: "S",
        daysOfWeek: ["Lu","Ma","Me","Gi","Ve","Sa","Do"],
        monthNames: ["Gennaio","Febbbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"],
        firstDay: 1  
      },
      showDropdowns: true,
      
    }

  

    /*<span style={{margin : "10px 10px 10px 10px"}}>da</span>
          <TextField key={idx} name="da" margin="dense" id={(col.columnName+ "da")} type={col.type} onChange={handleChange} value={valueda}>
          </TextField>
          <span style={{margin : "10px 10px 10px 10px"}}>a</span>
          <TextField key={(idx+99)} margin="dense" id={(col.columnName + "a" )} name="a" type={col.type} onChange={handleChange} value={valuea} >
          </TextField>*/
    const creaForm = (col,idx) => { 
      let value = ''
      let valueDa=''
      let valueA=''
      let valueInput=' '
      let options = optionsDef
      let arrFilters= []
      if (arrayFilters === null){
        arrFilters=null;
      }
      else if (arrayFilters.length){
        for(let x = 0 ; x<arrayFilters.length;x++){
          if (arrayFilters[x].columnName===col.columnName){
            if (arrayFilters[x].tipo === 'date'){
              valueDa=  Moment(arrayFilters[x].valore).format("DD/MM/YYYY") 
              valueA= Moment(arrayFilters[x].endDate).format("DD/MM/YYYY") 
            }
            else{
              value= arrayFilters[x].valore
            } 
                       
          }         
        }
        arrFilters.push(...arrayFilters)
      }



      if(valueDa === '' && valueA === '')
        options.autoUpdateInput = false 
      else{
        if(valueDa !==''){
          options.startDate = valueDa
          valueInput= valueDa 
        }
        if(valueA !== ''){
          options.endDate = valueA
          valueInput  += ' - ' + valueA
        }
        options.autoUpdateInput = true
      }
      
      return (<NedModelDialogSingleCard col={col} clearAll={clearAll} key={idx} arrFilters={arrFilters} setArrayFilters={setArrayFilters} options={options} value={value} valueInput={valueInput}  /> )
    }

    const customSorting = (v) =>{
   
      let temp
      let arrLength=v.length

      for(let x=0;x<arrLength;x++){
        if((x+1)%3===0){
          if (v[x].type ==='date'){
            temp=v[x-1]
            v[x-1]=v[x]
            v[x]=temp
          }          
        }
      } 
      return v 
    }
   




      useEffect(()=>{
        
        if(filters.length)
          setArrayFilters(filters)
        else
          setArrayFilters([])          
                
      },[filters])

  return (
    <div>     
      <Dialog open={open}  aria-labelledby="form-dialog-title"  >        
        <DialogTitle id="form-dialog-title"  >
          <div className="container-title-modal">
            
            <span><strong className="text-primary">FILTRI</strong></span>
            
            <BtnCloseFormDialogRenderer  className="ned-filter-close" open={open} setOpen={setOpen}/>
          </div>
        
        </DialogTitle>           
        <DialogContent id="contenuto">     
          {customSorting(columnFilterable).map(creaForm)}                      
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClear} className="ned-button-primary">
            Pulisci
          </Button>
          <Button  onClick={handleClose} className="ned-button-primary">
            Applica
          </Button>
        </DialogActions>          
      </Dialog>
    </div>
  );
}
