import React, { useEffect ,useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {getPermissions} from "../../services/permessi"
import TextField from '@material-ui/core/TextField';

const Profile = () => {
  const { user } = useAuth0();
  const { name, picture, email } = user;  
  const [permessi,setPermessi] = useState();
  const {getAccessTokenSilently} = useAuth0();
  useEffect( async ()=>{    
    const token = await getAccessTokenSilently()
    const perm= await getPermissions(token,email)
    setPermessi(perm)
  },[]);

  return (     
    <div>
      <div className="row align-items-center profile-header">
        <div className="col-md-2 mb-3">
          <img
            src={picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </div>
        <div className="col-md text-center text-md-left">
          <h2>Benvenuto, {name}</h2>
          <p className="lead text-muted">{email}</p>
        </div>
      </div>
      <div className="row p-4">    
         <TextField id="Name" className="p-2" label="Nome" value={name} />
         <TextField id="Email" className="p-2" label="E-Mail" value={email} />
         {permessi && permessi.titolo ?
              <TextField id="TitoloRuolo" className="p-2" label="Ruolo" value={permessi.titolo} />
            : ''  }
         {permessi && permessi.descrizione ?
              <TextField id="DescrizioneRuolo" className="p-2" label="Descrizione" value={permessi.descrizione} />
            : ''  }
      </div>
      <div className="row">
        {/* <pre className="col-12 text-light bg-dark p-4">
          {JSON.stringify(permessi, null, 2)}
        </pre> */}
      </div>
    </div>
  );
};
export default Profile;