import React , {useState,useLocation,useRef,useHistory, useEffect} from 'react'
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridCustom from "../../components/Grid/GridCustom"
import Axios from 'axios';
import {configAxios} from "../../services/axios"
import Moment from 'moment'
import { useAuth0 } from '@auth0/auth0-react';
const datiColonneExample=
{
    "colonne": [
      {
        "codice": "codColonna1",
        "nome": "Nome Colonna 1",
        "tipo": "STRING",
        "info": {
          "ColumnSize": "250px",
          "isFixed": true
        },
        "flag": []
      },
      {
        "codice": "codColonna2",
        "nome": "Nome Colonna 2",
        "tipo": "STRING",
        "info": {
          "ColumnSize": "250px",
          "isFixed": true
        },
        "flag": []
      },  
      {
        "codice": "codColonna3",
        "nome": "Nome Colonna 3",
        "tipo": "STRING",
        "info": {
          "ColumnSize": "250px",
          "isFixed": true
        },
        "flag": []
      }
    ]
}
const datiRigheExample =
{ 
  "righe": [
    {
      "valoriColonne": {
        "codColonna1": "valueCol1",
        "codColonna2": "valueCol2",
        "codColonna3": "valueCol3"
      },
      "id": "id1"
    },
    {
      "valoriColonne": {
        "codColonna1": "valueCol1",
        "codColonna2": "valueCol2",
        "codColonna3": "valueCol3"
      },
      "id": "id2"
    },
    {
      "valoriColonne": {
        "codColonna1": "valueCol1",
        "codColonna2": "valueCol2",
        "codColonna3": "valueCol3"
      },
      "id": "id3"
    }
  ],
  "count":3
}
const Users = (props) =>{
  const tipologia = props.Infotipologia;
  const detailDocumentInfo = props.detailDocumentInfo
  const setDetailDocumentInfo = props.setDetailDocumentInfo
  const [columns, setColumns] = useState([]);
  const [columnWidths, setColumnWidths] = useState([]);
  const [columnsFixed, setColumnsFixed] = useState([]);
  const [columnsHidden, setColumnsHidden] = useState([]);
  const [columnExtensions,setColumnExtensions] = useState([]);
  const [columnFilterable, setColumnFilterable] = useState([])
  const [rows, setRows] = useState([]); 
  const [isLoadingDocs, setIsLoadingDocs] = useState(true);
  const [selectedDocumentsService, setSelectedDocumentsService] = useState([]); 
  const [isLoadingHeaders, setIsLoadingHeaders] = useState(true);
  const [deleteTrigger,setDeleteTrigger]=useState(false)  
  const [filters, setFilters] = useState([]);
  const [sorting, setSorting] = useState([]); 
  const [currentPage, setCurrentPage] = useState(0);
  const [callBackTrigger,setCallBackTrigger]= useState(false);
  const [onSubmitTrigger,setOnSubmitTrigger] = useState(false)
  const [totalCount,setTotalCount]=useState(0)
  const [paramsTrigger,setParamsTrigger]=useState(false)  
  const [inOpen,setInOpen]=useState(false)
  const [inOpenConfirm,setInOpenConfirm]=useState(false)
  const [messageAlert,setMessageAlert]=useState ("testmess0")
  const [titleAlert,setTitleAlert]=useState ("testtitle0")
  const TopParam=20
  const Param='?$top='+TopParam;
  //const URLgetDocs= 'http://localhost:5000/'+tipologia+Param; 
  const nomeTabella='utenti'
  const server=sessionStorage.getItem("BACKEND")
  const URLgetDocs= server + '/api/Table/visualizza/'+nomeTabella + Param
  const URLgetHeaders= server + '/api/Table/colonne/'+nomeTabella
  const [openDocumentInfo, setOpenDocumentInfo] = useState(false)
  const [openDocumentVersion, setOpenDocumentVersion] = useState(false)
  const [openDocumentEdit, setOpenDocumentEdit] = useState(false)
  const toastId = useRef(null);
  const {getAccessTokenSilently} = useAuth0();
  
  const handleClickOpenDocumentInfo = () => {
    console.log('handleClickOpenDocumentInfo -- init --')
    setOpenDocumentInfo(true)
  }  
  const handleClickOpenDocumentVersion = () => {
    console.log('handleClickOpenDocumentVersion -- init --')
    setOpenDocumentVersion(true)
  }  
  const handleClickOpenDocumentEdit = () => {
    console.log('handleClickOpenDocumentEdit -- init --')
    setOpenDocumentEdit(true)
  }

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  function jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  

  const getCustomDate = (value) =>{
    return Moment(value).format("DD/MM/YYYY")
   }


  const getCustomDateAMG = (value) =>{
    return Moment(value).format("YYYY-MM-DD")
  }
  const getCustomDateAMGEndDate = (value) =>{
    return Moment(Moment(value).add(1,"day")).format("YYYY-MM-DD")
  }
 
  const getDocs = async  (token) => {
    setIsLoadingDocs(true)   
    const queryString = URLgetDocs
    const response = await Axios
            .get(queryString,configAxios(token))
            .catch(
              console.log("Error Initial rows")
              );

    if (response && response.data) {
        let json=response.data                    
        // getHeader(datiColonneExample.colonne)              
        let outRows = [];
        json.righe.map(riga => {
          var id = riga.id
          var outId = { id };                         
          var output = {};                
          output = riga.valoriMetadati;               
          output = jsonConcat(outId, output);
          outRows.push(outId);
        })
        setTotalCount(json.count);
        console.log("New rows:", outRows)
        if (outRows.length>TopParam)
          outRows.pop()
        setRows(outRows);          
      }
      setIsLoadingDocs(false);
  }

  const getHeader = async (token) => {  
      setIsLoadingHeaders(true)
      setIsLoadingDocs(true)
     // const token = await getAccessTokenSilently()
      const queryString = URLgetHeaders
      const response = await Axios
              .get(queryString,configAxios(token))
              .catch(
                console.log("Error Initial rows")
                );

      if (response && response.data) {
          let json=response.data
          let jsonObj = []; // colonne e titoli delle colonne (Header) formato {name,title}      
          let jsonObjWidths = []; // colonne e grandezza, deve avere lo stesso numero di colonne del jsonObj formato {columnname,width}
          let jsonObjFixed = []; // colonne che sono fissate a sinistre formato ["nomecolonna1","nomecolonna2"]
          let jsonObjHidden = []; // colonne nascoste formato ["nomecolonna1","nomecolonna2"] 
          let jsonObjExtensions = [] // le proprietà extra alle colonne      
          let jsonObjFilterable = []      
          jsonObj.push({name: "id",title: "id"},{name: "btnInfo", title: " "},{name: "btnDownload",title: " "},{name: "btnEdit",title: " "},{name: "btnUpload",title: " "},{name: "btnDelete",title: " "})
          jsonObjWidths.push({columnName: "id",width: "100"},{columnName: "btnInfo",width: "36"},{columnName: "btnDownload",width: "36"},{columnName: "btnEdit",width: "36"},{columnName: "btnUpload",width: "36"},{columnName: "btnDelete",width: "36"})
          jsonObjFixed.push("btnInfo","btnDownload","btnEdit","btnUpload","btnDelete")      
          jsonObjHidden.push("id")   
          let w;
          for (let i = 0; i < json.length; i++) {
            var item = json[i]
            jsonObj.push({
              name: item['codice'],
              title: item['nome'],
              type: item['tipo']
            })   
            if (item.info.columnSize=== undefined)
              w='150'        
            else
              w= String(item.info.columnSize).replace("px","")
            
            jsonObjWidths.push({
              columnName: item['codice'],
              width: w        
            })
    
            if (item.info.isFixed){
              jsonObjFixed.push(
                item['codice']
              )
            }
    
            if (item.info.isHidden){
              jsonObjHidden.push(
                item['codice']
              )
            }        
            else{
              let tipo
              if(item['tipo'] === 'STRING')
               tipo= 'text'
              else if(item['tipo'] === 'DATETIME')
               tipo = 'date'
              jsonObjFilterable.push({columnName: item['codice'] , type: tipo, label: item['nome']});
            }
    
            jsonObjExtensions.push({
              columnName: item['codice'],       
              wordWrapEnabled: true
            })       
        
          }
          
          console.log('json Obj : ',jsonObj)
          console.log('json ObjW : ',jsonObjWidths)
          console.log('json ObjF : ',jsonObjFixed)
          console.log('json ObjH : ',jsonObjHidden)
    
          setColumns(jsonObj);
          setColumnWidths(jsonObjWidths);
          setColumnsFixed(jsonObjFixed);
          setColumnsHidden(jsonObjHidden);
          setColumnExtensions(jsonObjExtensions);
          setColumnFilterable(jsonObjFilterable);
          setIsLoadingHeaders(false);
          console.log("NEDTABLELJ_DS: ",jsonObj)
       } 
  }

 

  useEffect(async ()=>{      
        const token = await getAccessTokenSilently({audience: `https://api-backend-agenzia-cifi.it` , scope: "read:current_user", })
        getHeader(token);
        getDocs(token)        
  },[]);
 
    return (
        <>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>                
                <GridCustom 
                 height={'30vh'}
                 setInOpenConfirm={setInOpenConfirm} inOpenConfirm={inOpenConfirm} 
                 handleClickOpenDocumentVersion={handleClickOpenDocumentVersion}
                 handleClickOpenDocumentEdit={handleClickOpenDocumentEdit} 
                 handleClickOpenDocumentInfo={handleClickOpenDocumentInfo} setDetailDocumentInfo={setDetailDocumentInfo}
                 messageAlert={messageAlert} setMessageAlert={setMessageAlert} titleAlert={titleAlert} setTitleAlert={setTitleAlert} 
                 inOpen={inOpen} setInOpen={setInOpen} 
                 paramsTrigger={paramsTrigger} setParamsTrigger={setParamsTrigger} 
                 totalCount={totalCount} setTotalCount={setTotalCount}                   
                 callBackTrigger={callBackTrigger} setCallBackTrigger={setCallBackTrigger}
                 deleteTrigger={deleteTrigger} setDeleteTrigger={setDeleteTrigger}
                 currentPage={currentPage} setCurrentPage={setCurrentPage}                 
                 sorting={sorting} setSorting={setSorting}
                 filters={filters} setFilters={setFilters} TopParam={TopParam} 
                 setOpen={setOpen}  open={open}
                 rowsObj={rows} columnsObj={columns} columnWidthsObj={columnWidths} columnFixedObj={columnsFixed} columnHiddenObj={columnsHidden}
                 selectedDocumentsService={selectedDocumentsService} setSelectedDocumentsService={setSelectedDocumentsService}
                 tipologiaObj={tipologia} columnExtensions={columnExtensions} URLgetDocs={URLgetDocs} columnFilterable={columnFilterable} />  
            </GridItem>           
          </GridContainer>
        </>
     ) ;

}

export default Users;

