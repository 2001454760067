import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const BtnCloseFiltersRenderer= (props) => {
    
    const handleClickFilter = (e) =>{
        let f = []
        f.push(...props.filters)
        f.splice(props.id,1)
        props.setFilters(f)
    }
      return (
        <IconButton id={props.id} aria-label="close" onClick={handleClickFilter} style = {{padding: 0}}>
            <CloseIcon  fontSize="small"/>
        </IconButton>
      )
    
  }
  export default BtnCloseFiltersRenderer;