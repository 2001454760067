import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';



const loading = () => {
  
  return (
    <div className="m-5 p-5 d-flex justify-content-center" >  
      <p>Connessione in corso...</p>
      <CircularProgress className="ml-3" size="25px" />    
      </div>
  );
}

export default loading;