import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { deleteDoc } from '../services/DeleteFileService';
// import {restoreDoc} from '../services/RestoreFileService'

export default function NEDAlertDialogConfirmType({title, message,inOpen,setInOpen, id}) { 

  const handleClose = () => {    
    setInOpen(false);
  };

  const handleDialogOK= () =>{    
    // if (String(title).includes("Ripristino"))
    //   restoreDoc(id)
    // else
    //   deleteDoc(id)
    setInOpen(false);
  }

  return (
    <div>     
      <Dialog
        open={inOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>   
          <Button onClick={handleDialogOK} color="primary"  >
              Conferma
          </Button>
          <Button color="primary" onClick={handleClose}>
              Annulla
          </Button>     
        </DialogActions>
      </Dialog>
    </div>
  );
}
