import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';


function BtnCellEditRenderer(props) {
  let isEnable = false
  const mod = sessionStorage.getItem('ARCHIVIO_MODIFICA')
  if(mod=== "ENABLE")
  { 
    isEnable= true
  }

    const onBtnClickedHandler = (e) => {
      console.log("Calling edit for document : " , props)
      //let url = window.location.href
      //window.location.href = url + '/' + props.row.id
      if (isEnable){
      props.row.contents.customFunction(props.row) //setIDDocument
      
      props.row.contents.handleClickOpenDocumentEdit()
      
    }else{
      props.setTitleAlert("Attenzione")
      props.setMessageAlert("Funzionalità non disponibile")
      props.setInOpen(true)
    }
      //props.setTitleAlert("Attenzione")
      //props.setMessageAlert("Funzionalità non disponibile")
      //props.setInOpen(true)
    }
    //, color:'#242423'className={'text-primary'}
      return (
        <IconButton aria-label="edit" style={isEnable?{padding: '6px'}:{padding: '6px',color:'#d1d1d1'}} className={isEnable?'text-primary':''} onClick={onBtnClickedHandler}>
          <EditIcon fontSize="small" />
        </IconButton>
      )
    
  }
  export default BtnCellEditRenderer;