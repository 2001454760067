import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import BtnCellDownloadRenderer from '../CustomButtons/BtnCellDownloadRenderer';
import BtnCellUploadRenderer from '../CustomButtons/BtnCellUploadRenderer';
import BtnCellEditRenderer from '../CustomButtons/BtnCellEditRenderer';
import BtnCellDeleteRenderer from '../CustomButtons/BtnCellDeleteRenderer';
import BtnCellRestoreRenderer from '../CustomButtons/BtnCellRestoreRenderer';
import BtnCellInfoRenderer from '../CustomButtons/BtnCellInfoRenderer';

import NEDLoadingTable from './NEDLoadingTable'
import NEDModalDialog from './NEDModalDialog'
import BtnCloseFiltersRenderer from '../CustomButtons/BtnCloseFiltersRenderer';
import NEDAlertDialog from './NEDAlertDialog'
import NEDAlertDialogConfirmType from './NEDAlertDialogConfirmType'

import {
  SelectionState,
  PagingState,
  CustomPaging,
  IntegratedSelection,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  VirtualTable,
  TableHeaderRow,
  TableSelection,
  TableColumnVisibility,
  PagingPanel,
  TableFixedColumns, TableColumnResizing
} from '@devexpress/dx-react-grid-material-ui';

import Moment from 'moment';
import { useLocation, useHistory } from 'react-router-dom';







const getRowId = row => row.id;

//render delle celle comuni
const Cellupd = (restProps ) => {
  let value = restProps.props.value
  let leftcolumns = restProps.leftcolumns
  if (Array.isArray(value)){
    let tmp=""
    value.forEach(v => {
      if(tmp.length)
        tmp = tmp + ',' + v;
      else
        tmp = v;
    });
    value=tmp
  }
  if ( isInside(restProps.props.column.name,leftcolumns)){
  return (
  <Table.Cell
  {...restProps.props}
    style={{      
      ...restProps.props.style, padding:'1 0 0 0', backgroundColor : '#f8f9fa'
    }}

  >
    <Tooltip title={(
      <span>
        {`${value}`}
      </span>
    )}
    >
      <span
      style={{
        fontSize:'11px', wordWrap: 'break-word' , color:'#242423' ,    fontWeight : 'bold'
      }}
      >
      {value}
      </span>
    </Tooltip>
   
    
  </Table.Cell>)
  }
  else{
    return(
    <Table.Cell
    {...restProps.props}
    style={{      
      ...restProps.props.style, padding:'1 0 0 0'
    }}
  >
    <Tooltip title={(
      <span>
        {`${value}`}
      </span>
    )}
    >
      <span
      style={{
        fontSize:'11px', wordWrap: 'break-word'
      }}
      >
      {value}
      </span>
    </Tooltip>
   
    
  </Table.Cell>)

  }
  
};
const CellupdDate = (restProps) => {
  let leftcolumns = restProps.leftcolumns
  var value= Moment(restProps.props.value).format("DD/MM/YYYY")
  if ( isInside(restProps.props.column.name,leftcolumns)){
  return(
  <Table.Cell
    {...restProps.props}
    style={{      
      ...restProps.props.style, padding:'1 0 0 0', backgroundColor : '#f8f9fa'
    }}
  >
    <Tooltip title={(
      <span>
        {`${value}`}
      </span>
    )}
    >
      <span
      style={{
        fontSize:'11px' , color:'#242423',    fontWeight : 'bold'
      }}
      >
      {value}
      </span>
    </Tooltip>
   
    
  </Table.Cell>);
  }
  else{
    return (
      <Table.Cell
      {...restProps.props}
      style={{      
        ...restProps.props.style, padding:'1 0 0 0'
      }}
    >
      <Tooltip title={(
        <span>
          {`${value}`}
        </span>
      )}
      >
        <span
        style={{
          fontSize:'11px'
        }}
        >
        {value}
        </span>
      </Tooltip>
     
      
    </Table.Cell>)   
  }
};
//render delle celle della colonna con il btn edit
const CellupdBtnEdit = (restProps) => (
  <Table.Cell
    {...restProps.props}
    style={{      
      ...restProps.props.style, padding:'0px', borderBottom: '2px solid rgba(224, 224, 224, 1) ', backgroundColor : '#f8f9fa'
    }}
  >
     <Tooltip title={(
      <span>
        {`Modifica`}
      </span>
    )}
    >
    <span>
    <BtnCellEditRenderer  {...restProps.props} />
    </span>
    </Tooltip>
  </Table.Cell>
);
//render delle celle della colonna con il btn download
const CellupdBtnDownload = (restProps) => (
  
  <Table.Cell
    {...restProps.props}
    style={{      
      ...restProps.props.style, padding:'0px', borderBottom: '2px solid rgba(224, 224, 224, 1) ', backgroundColor : '#f8f9fa'
    }}
  >
    <Tooltip title={(
      <span>
        {`Visualizza`}
      </span>
    )}
    >
    <span>
    <BtnCellDownloadRenderer {...restProps.props} />
    </span>
    </Tooltip>
  </Table.Cell>
);
//render delle celle della colonna con il btn upload
const CellupdBtnUpload = (restProps) => (
  <Table.Cell
    {...restProps.props}
    style={{      
      ...restProps.props.style, padding:'0px' , borderBottom: '2px solid rgba(224, 224, 224, 1) ', backgroundColor : '#f8f9fa'
    }}   
  >
     <Tooltip title={(
      <span>
        {`Nuova Versione`}
      </span>
    )}
    >
    <span>
    <BtnCellUploadRenderer {...restProps.props} />
    </span>
    </Tooltip>
  </Table.Cell>
);

const CellupdBtnInfo = (restProps) => (
  <Table.Cell
    {...restProps.props}
    style={{      
      ...restProps.props.style, padding:'0px', borderBottom: '2px solid rgba(224, 224, 224, 1) ', backgroundColor : '#f8f9fa'
    }}   
  >
     <Tooltip title={(
      <span>
        {`Info`}
      </span>
    )}
    >
    <span>
    <BtnCellInfoRenderer {...restProps.props} />
    </span>
    </Tooltip>
  </Table.Cell>
);

//render delle celle della colonna con il btn delete
const CellupdBtnDelete = (restProps) => (
  <Table.Cell
    {...restProps.props}
    style={{      
      ...restProps.props.style, padding:'0px', borderBottom: '2px solid rgba(224, 224, 224, 1) ',backgroundColor : '#f8f9fa'
    }}   
  >
     <Tooltip title={(
      <span>
        {`Cancella`}
      </span>
    )}
    >
    <span>
    <BtnCellDeleteRenderer   {...restProps} onBtnClickedHandler={()=>{
        restProps.setTitleAlert("Attenzione Cancellazione")
        restProps.setMessageAlert("Sei sicuro di voler proseguire con la cancellazione del documento?")         
        restProps.setIdDocToType(restProps.props.row.id) 
        restProps.setInOpenConfirmType(true) 
    }} />
    </span>
    </Tooltip>
  </Table.Cell>
);
const CellupdBtnRipristina = (restProps) => (
  <Table.Cell
    {...restProps.props}
    style={{      
      ...restProps.props.style, padding:'0px', borderBottom: '2px solid rgba(224, 224, 224, 1) ',backgroundColor : '#f8f9fa'
    }}   
  >
     <Tooltip title={(
      <span>
        {`Ripristina`}
      </span>
    )}
    >
    <span>
    <BtnCellRestoreRenderer   {...restProps} onBtnClickedHandler={()=>{
        restProps.setTitleAlert("Attenzione Ripristino")
        restProps.setMessageAlert("Sei sicuro di voler proseguire con il ripristino del documento?")         
        restProps.setIdDocToType(restProps.props.row.id) 
        restProps.setInOpenConfirmType(true)  
      }}/>
    </span>
    </Tooltip>
  </Table.Cell>
);

const Cell = (props) => {

  const { column } = props.props;
  
  if (column.name === ('btnInfo')){
    return <CellupdBtnInfo {...props} />
  } 
  else if(column.name === ('btnDownload')){
    return <CellupdBtnDownload {...props} />

  }else if (column.name === ('btnEdit') ) {
    return <CellupdBtnEdit {...props} /> 

  }else if (column.name === ('btnUpload') ) {
    return <CellupdBtnUpload {...props} />
  }
  else if (column.name === ('btnDelete') ) {    
    return  <CellupdBtnDelete {...props} />
  } 
  else if(column.name === ('btnRipristina') ){
    return <CellupdBtnRipristina {...props} />
  }
  else if (column.type==="DATETIME")  {
    return <CellupdDate {...props} />
    // return <CellupdDate {...props} />    
  }
  else
      return <Cellupd {...props} />;
  
 
};

const isInside = (name,arr) =>{

  for(let x=0;x<arr.length;x++){
    if (name === arr[x])
      return true
  }
  return false

}

const CellHeader = ({leftcolumns,...props}) => {
  if ( isInside(props.restprops.column.name,leftcolumns)){

    return (<TableHeaderRow.Cell
      {...props.restprops}
      style={{
        ...props.restprops.style, fontSize:"12px" , fontWeight:"bold", textAlign: "center" , backgroundColor : '#f8f9fa'
      }}
    >

    </TableHeaderRow.Cell>)
  }
  else{
    return (  <TableHeaderRow.Cell
      {...props.restprops}
      style={{
        ...props.restprops.style, fontSize:"12px" , fontWeight:"bold", textAlign: "center"
      }}
    >


    </TableHeaderRow.Cell>

  )}
};

const CellHeaderSelection = ({...props}) => (
    <TableSelection.HeaderCell   
    {...props}
    style={{      
      ...props.style, fontSize:"12px" , fontWeight:"bold", textAlign: "center", backgroundColor : '#f8f9fa'
    }}
  >    
  </TableSelection.HeaderCell >
  )

  
const CellSelection = ({...props}) => (
  <TableSelection.Cell
  {...props}
  style={{      
    ...props.style, fontSize:"12px" , fontWeight:"bold", textAlign: "center", backgroundColor : '#f8f9fa'
  }}
></TableSelection.Cell >
)

const NEDTableLJ = ({height,handleClickOpenDocumentInfo,handleClickOpenDocumentEdit,handleClickOpenDocumentVersion, inOpen,setInOpen,messageAlert,setMessageAlert,titleAlert,setTitleAlert,paramsTrigger,setParamsTrigger,totalCount,setTotalCount,setCallBackTrigger,callBackTrigger,currentPage,setCurrentPage,setDetailDocumentInfo, rowsObj,columnsObj,deleteTrigger,setDeleteTrigger,setFilters,filters,setSorting,sorting,TopParam,open,setOpen,columnWidthsObj,columnFixedObj,columnHiddenObj,selectedDocumentsService,setSelectedDocumentsService,tipologiaObj,columnExtensions,URLgetDocs,columnFilterable}) => {
  const [rows, setRows] = useState(rowsObj);
  const [columns, setColumns] = useState(columnsObj);   
  const [columnWidths, setColumnWidths] = useState(columnWidthsObj); // utilizzare se non si utilizza la VirtualTable oppure    se serve le columns resizable
  const [defaultHiddenColumnNames,setDefaultHiddenColumnNames] = useState(columnHiddenObj)
  const [selection, setSelection] = useState([]);
  //const [sorting, setSorting] = useState([]);
  //const [filters, setFilters] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState(selectedDocumentsService);
  const [leftColumns] = useState([TableSelection.COLUMN_TYPE].concat(...columnFixedObj));  
  const [filteringStateColumnExtensions] = useState([
    { columnName: 'btnInfo', filteringEnabled: false },
    { columnName: 'btnDownload', filteringEnabled: false },
    { columnName: 'btnEdit', filteringEnabled: false },
    { columnName: 'btnUpload', filteringEnabled: false },
    { columnName: 'btnDelete', filteringEnabled: false },
    { columnName: 'btnRipristina', filteringEnabled: false },
  ]);
  const [sortingStateColumnExtensions] = useState([
    { columnName: 'btnInfo', sortingEnabled: false },
    { columnName: 'btnDownload', sortingEnabled: false },
    { columnName: 'btnEdit', sortingEnabled: false },
    { columnName: 'btnUpload', sortingEnabled: false },
    { columnName: 'btnDelete', sortingEnabled: false },
    { columnName: 'btnRipristina', sortingEnabled: false },
  ]);
  const [tableColumnExtensions] = useState(columnExtensions); //columnExtensions
  const [maxCount,setMaxCount]=useState(false);
  const [pageSize] = useState(TopParam); // Deve essere uguale a TopParams se si vuole caricare lo stesso numero di righe quanto quelle delle pagine

  const [loading, setLoading] = useState(false);
  const [lastQuery, setLastQuery] = useState(URLgetDocs);
  const [lastSortMetadato,setLastSortMetadato] = useState();
  const [lastFiltersMetadati,setLastFiltersMetadati] = useState("");  
  const [previousPage,setPreviousPage] = useState(0);
  const [idDocToType,setIdDocToType]=useState(null);
  const [inOpenConfirmType,setInOpenConfirmType]=useState(false)
  
  useEffect(()=>{
    setRows(rowsObj);
    setColumns(columnsObj);   
    setColumnWidths(columnWidthsObj);
    setSelectedDocuments(selectedDocumentsService);
    setDefaultHiddenColumnNames(columnHiddenObj);
  },[rowsObj,columnsObj,columnWidthsObj,selectedDocumentsService,columnHiddenObj]);


  var resetZeroPage =false;
  var lastSortMetadatoTmp="";
  var lastFiltersMetadatiTmp="";
  function jsonConcat(o1, o2) {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  }

  const getSkipParamValue = () =>{
    let value=0
    let pag=currentPage
    // if(pag>2){
       value= pag * pageSize
    // }
    return value
  }

  const setZeroFlagPage = () =>{
    if(!paramsTrigger){
      setCurrentPage(0)
      resetZeroPage=true;
    }
    else
      setParamsTrigger(false)
    setSelection([])
    setSelectedDocuments([]);
    setSelectedDocumentsService([])
  }

 


  // useEffect(() => {
  //   // if(callBackTrigger){
  //   //       let tmp = []
  //   //       for (let x =0;x<selectedDocuments.length;x++){
  //   //         tmp.push(selectedDocuments[x].id)
  //   //       }
  //   //       setSelection(tmp)
  //   //       loadDataCallBack();
  //   //       loadParams(loc,history);
  //   // }else if (!loading){
  //   //   setSelectedDocumentsService(selectedDocuments);
  //   //   loadData();
  //   //   loadParams(loc,history);
  //   // }
  //   //console.log("rows :", rows, " \n  currentPage: ",currentPage, "  totalCount: ",totalCount)
  //   //console.log("selectedDocs:", selectedDocuments)
  //   return (()=>{
  //     if(deleteTrigger)setDeleteTrigger(false)
  //     if(callBackTrigger)setCallBackTrigger(false)
  //     }
  //   )
   
  // },[sorting,selection,currentPage,filters,deleteTrigger,totalCount,rowsObj]);

  const onSelectChange = (value) =>{  
    setSelection(value);
  } 
  
  const getCustomDate = (value) =>{    
    return Moment(value).format("DD/MM/YYYY")
   }
  
  
  const getCustomDateAMG = (value) =>{    
    return Moment(value).format("YYYY-MM-DD")
  }
  const getCustomDateAMGEndDate = (value) =>{    
    return Moment(Moment(value).add(1,"day")).format("YYYY-MM-DD")
  }


  const searchLabel = (col) =>{   
    let nameTest
    nameTest=col.columnName  
    for (let x =0; x < columnFilterable.length; x++){
      if (columnFilterable[x].columnName === nameTest)
        return columnFilterable[x].label
    }
    return "not found"
  }


  const creaFiltersCards = (col,idx) =>{
      if(col.tipo !== 'date'){ 
              return (
              <span key={idx} className="ned-filter-primary">
                <span>{searchLabel(col)} : {col.valore} &nbsp;&nbsp;</span>
                <BtnCloseFiltersRenderer id={idx} filters={filters} setFilters={setFilters}/>      
              </span>
              )
      }
      else{ 
        return (
              <span key={idx} className="ned-filter-primary">
                <span>{searchLabel(col)} : da {getCustomDate(col.valore)} a {getCustomDate(col.endDate)} &nbsp;&nbsp;</span>
                <BtnCloseFiltersRenderer id={idx} filters={filters} setFilters={setFilters}/>      
              </span>
        )
      }
            

  }
  const infoFN = (parameters) => {
    return (
     'da ' + parameters.from + " a " + parameters.to 
    )
  } 
  console.log("NEDTABLELJ: RIGHE",rows);
  console.log("NEDTABLELJ: RIGHEOBL",rowsObj);
  console.log("NEDTABLELJ: COLONNE",columns);
  return (
    <>

      <Paper>  
      {loading && <NEDLoadingTable />} 
        <>
        <NEDAlertDialogConfirmType title={titleAlert} id={idDocToType} message={messageAlert} inOpen={inOpenConfirmType} setInOpen={setInOpenConfirmType}/>
          <NEDAlertDialog title={titleAlert} message={messageAlert} inOpen={inOpen} setInOpen={setInOpen}/>
          <NEDModalDialog open={open} setOpen={setOpen} columnFilterable={columnFilterable} setFilters={setFilters} filters={filters} />
          <div className="mb-3 row"style={{'width': '100%'}}>
            <div className="text-align-left col-md-10 col-lg-10 col-sm-10">
              {filters.length ? filters.map((col, idx) =>(creaFiltersCards(col,idx))) :""}
            </div>
            <div className="text-align-end col-md-2 col-lg-2 col-sm-2">
              <div style={{marginRight: '24px', display: 'inline-block' , textAlign: 'left'}}>
                <span><b>Numero documenti : </b></span><span style={{display: 'inline-block', textAlign: 'right'}}><b> {totalCount} </b></span>
                <br/>
                <span><b>Numero documenti filtrati  : </b></span><span style={{display: 'inline-block', textAlign: 'right' }}><b> - </b></span>
              </div>
            </div>
          </div>
        </>
        <>
        <Grid rows={rowsObj} columns={columns} getRowId={getRowId}>
          <PagingState currentPage={currentPage} onCurrentPageChange={setCurrentPage} pageSize={pageSize} />
          <SelectionState selection={selection} onSelectionChange={onSelectChange} />
          <SortingState sorting={sorting} onSortingChange={setSorting}   columnExtensions={sortingStateColumnExtensions} />
          <CustomPaging totalCount={totalCount}/>
          <IntegratedSelection />
          <VirtualTable height={height}  cellComponent={(props)=>(<Cell setIdDocToType={setIdDocToType} setInOpenConfirmType={setInOpenConfirmType} setMessageAlert={setMessageAlert} setTitleAlert={setTitleAlert} setInOpen={setInOpen} leftcolumns={leftColumns} props={props}/>)} columnExtensions={tableColumnExtensions}/>
          <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={setColumnWidths} />
          <TableHeaderRow  showSortingControls={true}  cellComponent={(restprops)=>(<CellHeader leftcolumns={leftColumns} restprops={restprops}  />)} />
          <TableSelection showSelectAll cellComponent={CellSelection} headerCellComponent={CellHeaderSelection} />
          <PagingPanel messages={{info:infoFN}}  />
          <TableColumnVisibility defaultHiddenColumnNames={defaultHiddenColumnNames} />
          <TableFixedColumns leftColumns={leftColumns} />
        </Grid>
        </>
      </Paper>
    </>    
  )

}
export default NEDTableLJ;
