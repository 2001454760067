import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';



function BtnCellDeleteRenderer(props) {
    
    const onBtnClickedHandler = (e) => {
        // props.setTitleAlert("Attenzione")
        // props.setMessageAlert("Sei sicuro di voler proseguire con la cancellazione del documento?")
        // props.setInOpenConfirm(true)  
        //props.setOnConfirm(deleteDoc(props.props.row.id))      
    }
    
    
      return (   
      <IconButton aria-label="delete" style={{padding: '6px'}} className={'text-primary'}  onClick={props.onBtnClickedHandler}>
       <DeleteIcon  fontSize="small"/>
      </IconButton>
     
      )
      
    
  }
  export default BtnCellDeleteRenderer;