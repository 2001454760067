
import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import RestoreIcon from '@material-ui/icons/Restore';



const  BtnCellRestoreRenderer = (props) => {
  
    const onBtnClickedHandler = (e) => {
        //console.log("Calling  restore for document : " , props)
        // restoreDoc(props.row.id)
        //let url = window.location.href
        //window.location.href = url + '/' + props.row.id
        // props.row.contents.customFunction(props.row)
        // props.row.contents.handleClickOpenDocumentInfo()
        //alert("Info btn clicked")
    }



      return (
      <IconButton aria-label="info" style={{padding: '6px' }}  className={'text-primary'}  onClick={props.onBtnClickedHandler}>
       <RestoreFromTrashIcon  fontSize="small"/>
      </IconButton>
      )

  }

  export default BtnCellRestoreRenderer;
