import React from "react";
import { Redirect, Switch} from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "../components/Navbars/Navbar.js";
import Footer from "../components/Footer/Footer.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "../routes.js";
import styles from "../assets/jss/material-dashboard-react/layouts/adminStyle.js";
import logo from "../assets/img/logo.jpg";
import Loading from "../components/loading"
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "../components/ProtectedRoute"
const switchRoutes = (
  <Switch>
    {routes().map((prop, key) => {
      if (prop.layout === "/cs") {
        return (
          <ProtectedRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />          
        );
      }        
    })}
    <Redirect exact from="/" to="/cs/profile"/>    
    <Redirect exact from="/cs" to="/cs/profile"/> 
  </Switch>
);
const useStyles = makeStyles(styles);

const Admin = ( {...rest} ) => {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions  
  const [color, setColor] = React.useState("red"); 
  const { isLoading , isAuthenticated } = useAuth0();  

  if (isLoading) {
    return <Loading />;
  }  
  else {    
    return (
      <div className={classes.wrapper}>
          <Sidebar
            routes={routes}           
            logo={logo}                   
            color={color}
            {...rest}
          />
        <div className={classes.mainPanel} ref={mainPanel}>    
            <Navbar
              routes={routes}          
              {...rest}
            />    
            <div className={classes.map}>
                { isAuthenticated ?
                    switchRoutes 
                  :                     
                    <>           
                          <h1>Benvevuto xxx sul Portale Centro Sviluppo s.r.l.</h1>
                          <h2>Effettua il login tramite il bottone nell'area Utente (in alto a destra)</h2> 
                    </>                   
                }               
            </div>    
            <Footer /> 
          </div>
      </div>
    );
  }
}
export default Admin;
  