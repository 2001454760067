import React , {useState,useLocation,useRef,useHistory, useEffect, componentDidMount} from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button  from "@material-ui/core/Button";
import Axios from 'axios'
import {configAxios} from "../../services/axios"
import { useAuth0 } from "@auth0/auth0-react";
import CalendarioRiga from './CalendarioRiga';
import CalendarioRichiesta from './CalendarioRichiesta';
import CalendarioApprovazioni from './CalendarioApprovazioni';
import {getPermissions} from '../../services/permessi'

const monthNames = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const RowContainer = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
`;

const Calendario = () => {  
  const { user } = useAuth0();
  const { name, picture, email } = user; 
  const [permessi,setPermessi] = useState();
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [events, setEvents] = useState([]);  
  const weeks = []; 
  const server=sessionStorage.getItem("BACKEND")
  const {getAccessTokenSilently} = useAuth0();

  const firstDayInMonth = new Date(year, month - 1, 1),
    lastDayInMonth = new Date(year, month, 0),
    firstDayInCalendar = new Date(
      year,
      month - 1,
      1 - firstDayInMonth.getDay()
    ),
    lastDayInCalendar = new Date(
      year,
      month - 1,
      lastDayInMonth.getDate() + 6 - lastDayInMonth.getDay()
    );
    
    const loadPrevMonth = () => {
      let prevMonth = month - 1;
      if (prevMonth < 1) {
        setYear(year - 1);
        prevMonth = 12;
      }
      setMonth(prevMonth);
    };
    
    const loadNextMonth = () => {
      let nextMonth = month + 1;
      if (nextMonth > 12) {
        setYear(year + 1);
        nextMonth = 1;
      }
      setMonth(nextMonth);
    };  
    
    const getDati = async (permission) =>{
      let url = "";
      const token = await getAccessTokenSilently()
      const perm= await getPermissions(token,email)
      setPermessi(perm)
     if (perm != null){
        if(perm.indice == 0)
        url = server + '/api/Table/visualizza/lista_ferie?$top=20&$filter=Like-email_user-'+email;
        else
        url = server + '/api/Table/visualizza/lista_ferie?$top=20';
        const response = await Axios
        .get(url,configAxios(token))
        .catch((e)=>{
          console.log(e);
        })
        if (response && response.data){
          let i = 0;
          console.log(response.data);
          let appointments = response.data;
          const newEvents = [];
    
          appointments.righe.map(riga => {
            var id = riga.id                         
            var output = {};     
            output = riga.valoriMetadati;
            newEvents.push({id: i + 1,
              permis: permission.permesso,
              title: output.descrizione,
              user:output.email_user,
              approved: output.approvato,
              data_richiesta: output.data_richiesta.split(" ")[0],
              data_inizio: output.data_inizio.split(" ")[0],
              data_fine: output.data_fine.split(" ")[0],
              beginDate: new Date(output.data_inizio.split(" ")[0].split("/")[2], output.data_inizio.split(" ")[0].split("/")[1] - 1, output.data_inizio.split(" ")[0].split("/")[0]),
              endDate: new Date(output.data_inizio.split(" ")[0].split("/")[2], output.data_inizio.split(" ")[0].split("/")[1] - 1, output.data_fine.split(" ")[0].split("/")[0])});
          }) 
          setEvents(newEvents);
        }      
      }
    }
    const makeVarious = async () =>{
       let permission = await getPermissions()
       getDati(permission) 
      }

    useEffect(() => {
      makeVarious()
    },[year, month]);


  let currentDate = firstDayInCalendar;
  while (currentDate <= lastDayInCalendar) {
    if (currentDate.getDay() === 0) {
      weeks.push([]);
    }
    weeks[weeks.length - 1].push(new Date(currentDate));
    currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  }

  return (
      
    <Container>
      <CalendarioRichiesta />
      <Container style={{"display":"inline-block", "text-align":"center"}}>
      
        </Container>
        <Container style={{"display":"inline-block", "text-align":"center"}}>
        <Button onClick={loadPrevMonth}>&laquo; Mese precedente</Button>
        <Button onClick={loadNextMonth}>Mese Successivo &raquo;</Button>
        </Container>
    <Container>
      <Header>
        <h1>
          {monthNames[month - 1]} {year}
        </h1>
      </Header>
      <RowContainer>
        {weeks.map((dates, i) => (
          <CalendarioRiga 
            year={year}
            month={month}
            dates={dates}
            events={events.filter(
              event =>
                event.beginDate <= dates[dates.length - 1] &&
                event.endDate >= dates[0]
            )}
            key={i}
          />
        ))}
      </RowContainer>
    </Container>
          <CalendarioApprovazioni 
            year={year}
            month={month}
            events={events}        
          />
    </Container>
  );
};
Calendario.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  events: PropTypes.arrayOf(PropTypes.object),
};
Calendario.defaultProps = {
  events: [],
};

export default Calendario;