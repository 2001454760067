import Axios from 'axios'
import {configAxios} from "./axios"


  export const getPermissions = async  (token, email) =>{    
    const server=sessionStorage.getItem("BACKEND")
    const URLgetPermessi= server + '/api/Token/permessi'
    const jsonData = {'email' : email}
    const response = await Axios
      .post(URLgetPermessi,jsonData,configAxios(token))
      .catch(()=>{console.log("err")})

    if (response && response.data){
      return response.data
    }
    else{
      console.log("err")
      return null
    }
  }