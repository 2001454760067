import DateRangePicker from 'react-bootstrap-daterangepicker';
import Moment from 'moment';
import React,{ useEffect, useRef }  from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-daterangepicker/daterangepicker.css';
import TextField from '@material-ui/core/TextField';
//{col,clearAll,setArrayFilters,value,valueInput,options,arrFilters}
const NedModelDialogSingleCard = (props)=>{  
    const myRef = useRef();
  
    const handleChange = (e) =>{
        let temp = []
        if (String(e.target.value).trim().length){
            if(props.arrFilters != null)
              temp.push(...props.arrFilters)   
            for(let x =0;x < temp.length; x++){
                if(temp[x].columnName === e.target.id )
                    temp.splice(x,1);
            }   
            temp.push({ 'columnName' : e.target.id, 'valore' : e.target.value , 'tipo': e.target.type })
            props.setArrayFilters(temp);
        }
        else{
          if(props.arrFilters != null)
              temp.push(...props.arrFilters)   
          for(let x =0;x < temp.length; x++){
              if(temp[x].columnName === e.target.id )
                  temp.splice(x,1);
          }
          props.setArrayFilters(temp);   
        }
      }
    
      const handleChangeDate = (e,picker) =>{
        let temp = []    
        if(props.arrFilters != null)
          temp.push(...props.arrFilters)
        let start = new Date(picker.startDate)
        let end = new Date(picker.endDate)
        for(let x =0;x < temp.length; x++){
          if(temp[x].columnName === e.target.id )
              temp.splice(x,1);   
        }   
        temp.push({ 'columnName' : e.target.id, 'valore' : start , 'tipo': 'date' , "endDate": end}) // 'valore' nel caso delle date viene settato come startDate
        props.setArrayFilters(temp);
        e.target.value=Moment(start).format("DD/MM/YYYY") + ' - ' + Moment(end).format("DD/MM/YYYY")
        
      }
    
      const handleCancelDate = (e,picker) => {
        let temp = []    
        if(props.arrFilters != null)
          temp.push(...props.arrFilters)
        let start = new Date(picker.startDate)
        let end = new Date(picker.endDate)
        for(let x =0;x < temp.length; x++){
          if(temp[x].columnName === e.target.id )
              temp.splice(x,1);   
        }   
        props.setArrayFilters(temp);
        e.target.value= ' ';
      }
    useEffect(()=>{
      if(props.col.type === 'date'){
        if(props.arrFilters === null){
          myRef.current.ref.value= ' '         
        }
      }
    },[props.arrFilters]);
    
    return ( 
      (props.col.type === 'date') ? 
            <div  style={{padding : "10px 10px 10px 10px", display:"inline-grid",width: "33%"}}>
            <span>{props.col.label}</span>
            <div className="react-bootstrap-daterangepicker-container" >
                <DateRangePicker initialSettings={props.options} ref={myRef} onApply={handleChangeDate} onCancel={handleCancelDate} >
                    <input type="text" id={props.col.columnName} className="form-control" defaultValue={props.valueInput} />
                </DateRangePicker>
            </div>
            </div>
          :
            <div   style={{display:"inline-grid" , padding : "10px 10px 10px 10px" ,width: "33%"}}>
                <span>{props.col.label}</span>
                <TextField margin="dense" id={props.col.columnName} type={props.col.type} onChange={handleChange} value={props.value} >
                </TextField>
            </div>
    )}
export default NedModelDialogSingleCard;
