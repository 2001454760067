import React, { Fragment, useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button  from "@material-ui/core/Button";
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { green } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';
import Axios from 'axios'
import {configAxios} from "../../services/axios"
import { useAuth0 } from "@auth0/auth0-react";
import {getPermissions} from '../../services/permessi'

const Row = styled.div`
  border-radius:15px;
  width: 100%;
  column-count: 5;
  padding:20px 0px 20px 0px;
  margin: 18px 0px;
`;

const Column = styled.div`
    text-align: center;
`;

const CalendarioApprovazioni = props => {
  const { year, month, events} = props;
  const { user } = useAuth0();
  const { name, picture, email } = user; 
  const [permessi,setPermessi] = useState();
  const {getAccessTokenSilently} = useAuth0();
  const server = sessionStorage.getItem("BACKEND");

  const handleApprove = async  (event, approveState) =>{
    const token = await getAccessTokenSilently()
    let url= server + "/api/Table/aggiorna/lista_ferie?$filter=Like-email_user-"+event.user+"__Like-data_inizio-"+event.data_inizio+"__Like-data_fine-"+event.data_fine+"__Like-data_richiesta-"+event.data_richiesta+"__Like-descrizione-"+event.title+"&$params=approvato-"+approveState+"";    
    await Axios
      .put(url,null,configAxios(token))
      .then(response => {
      console.log(response)
    })
    window.location.reload();
  }

  useEffect( async () => {
    const token = await getAccessTokenSilently()
    const perm = await getPermissions(token,email)
    setPermessi(perm)
  },[])

  if(events.length !== 0)
  {
  if(permessi.indice == 1)
  {
  return (
    <div class="Container">
        <h3 style={{"border-bottom":"1px solid gray"}}>Nuove richieste</h3>
      {events.map(event => {
          if(event.approved === '0')
          {
              return (
            <Row style={{"border":"0.5px solid #ededed"}}>
                <Column class="userEventAdmin">{event.user}</Column>
                <Column class="titleEventAdmin" >{event.title}</Column>
                <Column class="dateStartEventAdmin">{event.data_inizio}</Column>
                <Column class="dateEndEventAdmin">{event.data_fine}</Column>
                <Column class="btnsEventAdmin">
                <Button onClick={handleApprove.bind(event, event, "1")} style={{"padding":"0px"}}><CheckIcon style={{ color: green[500]}}  /></Button>
                <Button onClick={handleApprove.bind(event, event, "2")} style={{"padding":"0px"}}><ClearIcon style={{ color: red[500]}} /></Button>
                </Column>
            </Row>
        );
          }
      })}
    </div>
  );
}else{return (<div></div>);} } else {return (<div></div>);}
};
CalendarioApprovazioni.propTypes = {
  year: PropTypes.number.isRequired,
  month: PropTypes.number.isRequired,
  permessi: PropTypes.number.isRequired,
};
CalendarioApprovazioni.defaultProps = {
  events: [],
};

export default CalendarioApprovazioni;