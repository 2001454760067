import React, { Fragment, useState } from "react";
import core from '@material-ui/core'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios'
import {configAxios} from "../../services/axios"
import { useAuth0 } from "@auth0/auth0-react";
import {getPermissions} from '../../services/permessi'

const useStyles = makeStyles((theme) => ({
  container: {
    "display":"inline-block"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export default function FormDialog() {
  const classes = useStyles();
  const { user } = useAuth0();
  const { name, picture, email } = user;
  const [permessi,setPermessi] = useState();
  const [open, setOpen] = React.useState(false);
  const [dataSLoc,setDataSLoc]=useState(null)
  const [dataELoc,setDataELoc]=useState(null)
  const [description,setDescription]=useState(null)
  const server = sessionStorage.getItem("BACKEND");
  const {getAccessTokenSilently} = useAuth0();

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleSubmit = async  () =>{
    let url= server + "/api/Table/aggiungi/ferie"; 
    const token = await getAccessTokenSilently()
    const perm= await getPermissions(token,email)
    setPermessi(perm)
    let method = 'POST'
    let json={}
    json["email_user"]=email
    json["data_inizio"]=dataSLoc
    json["data_fine"]=dataELoc
    json["descrizione"]=description
    let response;  
    try {
      response = await Axios({
        url,
        method,
        data: json,
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*',
          'Authorization': 'Bearer '+ token
        }
      })
    } catch (err) {
      // Handle Error Here
      console.error(err);
      return err
    }
    if(response && response.data){
        console.log("######### Risposta:",response.data)
        //clear fields
        setDataSLoc("")
        setDataELoc("")
        setDescription("")
    }
    window.location.reload();
  }
  const onChangeDescription = (e) =>{
    setDescription(e.target.value)    
  }
  const onChangeDateS= (e) =>{
    setDataSLoc(e.target.value)    
  }
  const onChangeDateE = (e) =>{
    setDataELoc(e.target.value)    
  }
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{"display":"inline-block", "text-align":"center"}}>
      <Button variant="contained" onClick={handleClickOpen} style={{"float":"right", "background-color": "#5a1b22", "color":"#FFF"}}> <AddIcon /> Aggiungi richiesta
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Richiesta</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Invia una richiesta di giorni di permesso o di ferie, inserendo i dati appositi per la richiesta, per comunicare la tua assenza in caso di approvazione
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            onChange={onChangeDescription}
            value={description}
            id="descriptionRequest"
            label="Descrizione"
            type="text"
            fullWidth
          />
          <form noValidate style={{"text-align":"center", "margin": "6px 0px", "width": "100%"}}>
      <TextField style={{"width":"47%"}}
        id="date"
        label="Data inizio"
        type="date"
        onChange={onChangeDateS}
        value={dataSLoc}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField style={{"width":"47%"}}
        id="date"
        label="Data fine"
        type="date"
        onChange={onChangeDateE}
        value={dataELoc}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Indietro
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Invia
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}