import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

function BtnCloseFormDialogRenderer({open,setOpen}) {
    
    const handleClick = () =>{
        setOpen(!open);
    }
      return (
      <IconButton aria-label="close" onClick={handleClick} className="ned-filter-close" style={{ padding: "0px", position: "absolute"}} >
          <CloseIcon  fontSize="small"/>
      </IconButton>
      )
    
  }
  export default BtnCloseFormDialogRenderer;