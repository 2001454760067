import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
// core components/views for Admin layout
import DashboardPage from "./views/Dashboard/Dashboard.js";
import UserProfile from "./views/UserProfile/UserProfile.js";
import Profile from "./views/UserProfile/profile.js";
import Users from "./views/UserProfile/Users";
import TableList from "./views/TableList/TableList.js";
import Typography from "./views/Typography/Typography.js";
import Icons from "./views/Icons/Icons.js";
import Maps from "./views/Maps/Maps.js";
import LoginPage from "./views/LandingPage/LoginPage"
import SignUpPage from "./views/LandingPage/SignUpPage"
import NotificationsPage from "./views/Notifications/Notifications.js";
import Calendario from "./views/Calendario/Calendario"
import { useAuth0 } from "@auth0/auth0-react";
import { getPermissions } from "./services/permessi.js";
const dashboardRoutes = () => {
  const {user,getAccessTokenSilently} = useAuth0;
  const perm = undefined
  if (user){
    const {email} = user    
    const getPerm = async () => { 
      const token = await getAccessTokenSilently()
      perm= await getPermissions(token,email)}
    getPerm()
  }
  let routes = null
  if (perm !== undefined){
    routes=[{
      path: "/Utenti",
      name: "Utenti",   
      icon: Person,
      component: Users,
      layout: "/cs"
    },
    {
      path: "/calendario",
      name: "Calendario",    
      icon: "content_paste",
      component: Calendario,
      layout: "/cs"
    },]
  }
  else{
    routes=[ 
      {
        path: "/Utenti",
        name: "Utenti",   
        icon: Person,
        component: Users,
        layout: "/cs"
      },
      {
        path: "/calendario",
        name: "Calendario",    
        icon: "content_paste",
        component: Calendario,
        layout: "/cs"
      },
      {
        path: "/profile",
        name: "Profile",    
        icon: Person,
        component: Profile,
        layout: "/cs"
      },
    ]
  }
  return routes
} 

export default dashboardRoutes;
