import React from 'react';
// core components
import Admin from "./layouts/Admin.js";
import './index.css'
import Auth0ProviderMine from './Auth0ProviderMine'
import { Router } from 'react-router';
import {createBrowserHistory} from "history";


const endPointsFile='/env/endpoints.json';
const  loadEndPoints = () =>{
    var queryString = window.location.origin + endPointsFile;
    var req= new XMLHttpRequest();
    try{
        req.open("GET", queryString, false);
        req.send();
        let ris = JSON.parse(req.response);       
        sessionStorage.setItem("BACKEND",ris.BACKEND)       
    }
    catch{
        console.log("Caricamento endpoints fallito")
    }
}

const App = () => {
    const hist =  createBrowserHistory();   
    if (sessionStorage.getItem("BACKEND") === null)
        loadEndPoints() 
    return(           
        <Router history={hist}>
            <Auth0ProviderMine>
                <Admin/>
            </Auth0ProviderMine>
        </Router>
    );
}

export default App;