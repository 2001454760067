import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ClearIcon from '@material-ui/icons/Clear';

const Label = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column: ${props => props.col} / span ${props => props.colSpan};
  background: #5a1b22;
  font-size: 0.8rem;
  line-height: 0.8rem;
  padding: 9px 6px;
  color: #FFF;
`;
Label.defaultProps = {
  color: '#ddf',
};

const CalendarioEvento = props => {
  const { title, approved, col, colSpan, onClick } = props;

  return (
    <Label col={col} colSpan={colSpan} onClick={onClick}>
      {approved == 2 ? <ClearIcon style={{ fontSize: 17 }} /> : approved == 1 ? <CheckIcon style={{ fontSize: 17 }} /> : <ScheduleIcon style={{ fontSize: 17 }} />} - {title}
    </Label>
  );
};
CalendarioEvento.propTypes = {
  title: PropTypes.string,
  col: PropTypes.number,
  colSpan: PropTypes.number,
  onClick: PropTypes.func,
};
CalendarioEvento.defaultProps = {
  title: '',
  col: 1,
  colSpan: 1,
  onClick: () => {},
};

export default CalendarioEvento;