import React, { Component } from 'react';

import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';


const  BtnCellInfoRenderer = (props) => {
  
    const onBtnClickedHandler = (e) => {
        console.log("Calling info for document : " , props)
        //let url = window.location.href
        //window.location.href = url + '/' + props.row.id
        props.row.contents.customFunction(props.row)
        props.row.contents.handleClickOpenDocumentInfo()
        //alert("Info btn clicked")
    }



      return (
      <IconButton aria-label="info" style={{padding: '6px' }}  className={'text-primary'}  onClick={onBtnClickedHandler}>
       <InfoIcon  fontSize="small"/>
     </IconButton>
      )

  }

  export default BtnCellInfoRenderer;
